<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title v-if="$vuetify.breakpoint.lgAndUp">Vendor Annual Sales</v-toolbar-title>
          <v-toolbar-title v-else>Vendor</v-toolbar-title>
          <v-divider class="mx-2 grey" inset vertical></v-divider>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="2" text outlined v-bind="attrs" v-on="on" class="blue darken-2 ml-1"
                @click="request_formulation_list()">
                <v-icon color="white">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="2" v-bind="attrs" v-on="on" text outlined class="blue darken-2 ml-1"
                @click="export_list_excel()">
                <v-icon color="white">mdi-microsoft-excel</v-icon>
              </v-btn>
            </template>
            <span>Export list to Excel</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-text-field class="mt-5" v-model="vendors.search" label="Search"></v-text-field>
        </v-toolbar>
        <v-data-table id="vendorDataTable" :headers="vendors.headers"
                      :items="vendors.items"
                      :loading="vendors.loading" item-key="id"
                      :search="vendors.search"
                      fixed-header dense
                      :single-expand="true"
                      show-expand
                      hide-default-footer
                      :expanded.sync="vendors.expandsync"
                      @item-expanded="rowExpand">
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import async from 'async'
import axios from 'axios'
import { helperMixin } from '../mixins/helper'
import Headers from './data/items'

export default {
  name: "AnnualSalesbyItem",
  mixins: [ helperMixin ],
  data: () => ({
    vendors: {
      items: [ ],
      headers: [
        { text: "Company", align: "left", sortable: false, value: "" },
        { text: "Description", align: "left", sortable: false, value: "" },
        { text: "Code", align: "left", sortable: false, value: "" },
        { text: "Sales", align: "left", sortable: false, value: "" },
      ],
      loading: false,
      search: '',
      expandsync: [],
    },
    vs_button_larger: false,
  }),
  mounted: function () {

  },
  computed: { },
  methods: {
    canHazPermission(index) {
      return this.$store.getters.serializePermissions.toString(2)[index] == '1'
    },
    refreshData() {
      this.loadData()
    },
    cvt(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    cvk(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: value < 10 ? 1 : 0,
        maximumFractionDigits: value < 10 ? 1 : 0
      })
    },
    loadData () {

    },
  }
}
</script>

<style>
 #itemAnnualDataTable .v-data-table__wrapper{height:calc(100vh - 170px) !important;}
 #itemAnnualDataTable td:nth-child(n+8) { background-color:#e3f2fd; }
 #itemAnnualDataTable td:nth-child(8) { border-left: solid 2px black!important; }
</style>
